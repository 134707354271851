import "./App.css";
import auraThumbnail from "./images/aura thumbnail.png";
import dreamstateThumbnail from "./images/dreamstate thumbnail.png";
import midnightThumbnail from "./images/midnight thumbnail.png";
import { storage } from "./firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import LogIn from "./LogIn";
import { getAnalytics, logEvent } from "firebase/analytics";
import ComingSoon from "./ComingSoon";

function App() {
  // const [downloadUrls, setDownloadUrls] = useState({
  //   aura: "",
  //   dreamstate: "",
  //   midnight: "",
  // });

  // useEffect(() => {
  //   const getDownloadUrls = async () => {
  //     const auraUrl = await getDownloadURL(
  //       ref(storage, "one-shot-packs/aura by smplsrc.zip")
  //     );
  //     const dreamstateUrl = await getDownloadURL(
  //       ref(storage, "one-shot-packs/dreamstate by smplsrc.zip")
  //     );
  //     const midnightUrl = await getDownloadURL(
  //       ref(storage, "one-shot-packs/midnight by smplsrc.zip")
  //     );
  //     setDownloadUrls({
  //       aura: auraUrl,
  //       dreamstate: dreamstateUrl,
  //       midnight: midnightUrl,
  //     });
  //   };

  //   getDownloadUrls();
  // }, []);

  // const [showLogin, setShowLogin] = useState(false);
  // const analytics = getAnalytics();

  // const handleDownload = (packName) => {
  //   logEvent(analytics, "download", { pack: packName }); // Log custom event
  // };

  return (
    <div className="main-page">
      <ComingSoon />
    </div>
    // <div className="main-page">
    //   {!showLogin && (
    //     <div className="topBar">
    //       <h2>SMPL | SRC</h2>
    //       {/* <div>
    //         <div onClick={() => setShowLogin(true)} className="clear-button">
    //           Log In
    //         </div>
    //       </div> */}
    //     </div>
    //   )}
    //   {showLogin ? (
    //     <LogIn />
    //   ) : (
    //     <div className="main-page-container">
    //       <div className="top-section">
    //         <h2 className="main-heading">
    //           Royalty free one shot kits and melody packs.
    //         </h2>
    //       </div>
    //       <div className="divider"></div>
    //       <div className="mid-section">
    //         <h3 className="mid-heading">Currently Available Packs...</h3>
    //         <div className="pack-section">
    //           <div className="pack-box">
    //             <div className="pack-heading">Aura</div>
    //             <img src={auraThumbnail} className="thumbnail" />
    //             <div className="divider"></div>
    //             <div className="pack-description">Free one shot pack</div>
    //             <div className="divider"></div>
    //             {downloadUrls.aura.length > 0 ? (
    //               <a
    //                 href={downloadUrls.aura}
    //                 className="download-button"
    //                 onClick={() => handleDownload("aura")}
    //               >
    //                 Download
    //               </a>
    //             ) : (
    //               <div className="spacer-top-bottom-1">Loading...</div>
    //             )}
    //           </div>
    //           <div className="pack-box">
    //             <div className="pack-heading">Dreamstate</div>
    //             <img src={dreamstateThumbnail} className="thumbnail" />
    //             <div className="divider"></div>
    //             <div className="pack-description">Free one shot pack</div>
    //             <div className="divider"></div>
    //             {downloadUrls.dreamstate.length > 0 ? (
    //               <a
    //                 href={downloadUrls.dreamstate}
    //                 className="download-button"
    //                 onClick={() => handleDownload("dreamstate")}
    //               >
    //                 Download
    //               </a>
    //             ) : (
    //               <div className="spacer-top-bottom-1">Loading...</div>
    //             )}
    //           </div>
    //           <div className="pack-box">
    //             <div className="pack-heading">Midnight</div>
    //             <img src={midnightThumbnail} className="thumbnail" />
    //             <div className="divider"></div>
    //             <div className="pack-description">Free one shot pack</div>
    //             <div className="divider"></div>
    //             {downloadUrls.midnight.length > 0 ? (
    //               <a
    //                 href={downloadUrls.midnight}
    //                 className="download-button"
    //                 onClick={() => handleDownload("midnight")}
    //               >
    //                 Download
    //               </a>
    //             ) : (
    //               <div className="spacer-top-bottom-1">Loading...</div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
}

export default App;
