import React from "react";

export default function ComingSoon() {
  return (
    <div className="coming-soon">
      <div className="coming-soon-logo">SMPL | SRC</div>
      <div className="coming-soon-body-text-2">
        Your new source for high quality sample packs, 100% royalty free.
      </div>
      <div className="coming-soon-body-text-1">Coming Soon!</div>
    </div>
  );
}
